import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Balance, MessageCode, PlanTerms, SubscriptionDetail, SubscriptionPlan } from '@dougs/subscription/dto';
import { SubscriptionHttpService } from '../http/subscription.http';

interface SubscriptionState {
  signedTerms: Attachment[];
  balance: Balance;
  doubtfulBalance: Balance;
  plans: SubscriptionPlan[];
  plansPricing: SubscriptionPlan[];
}

@Injectable({
  providedIn: 'root',
})
export class SubscriptionStateService extends StateService<SubscriptionState> {
  signedTerms$: Observable<Attachment[]> = this.select((state) => state.signedTerms);
  balance$: Observable<Balance> = this.select((state) => state.balance);
  doubtfulBalance$: Observable<Balance> = this.select((state) => state.doubtfulBalance);
  plans$: Observable<SubscriptionPlan[]> = this.select((state) => state.plans);
  plansPricing$: Observable<SubscriptionPlan[]> = this.select((state) => state?.plansPricing || []);

  public openAcceptSubscriptionPlanPoliciesModal: Subject<void> = new Subject<void>();
  public openAcceptSubscriptionPlanPoliciesModal$: Observable<void> =
    this.openAcceptSubscriptionPlanPoliciesModal.asObservable();

  constructor(
    private readonly logger: LoggerService,
    private readonly subscriptionHttpService: SubscriptionHttpService,
  ) {
    super();
  }

  async updateSubscriptionPeriodsEndDate(company: Company, periodEndsAt: Date | string): Promise<void> {
    try {
      await lastValueFrom(this.subscriptionHttpService.updatePeriodsEndDate(company, periodEndsAt));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshSubscriptionSignedTerms(companyId: number): Promise<void> {
    try {
      this.setState({
        signedTerms: await lastValueFrom(this.subscriptionHttpService.getSignedTerms(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshPlans(companyId: number): Promise<void> {
    try {
      this.setState({
        plans: await lastValueFrom(this.subscriptionHttpService.getPlans(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async regenerateTermOfUse(companyId: number): Promise<void> {
    try {
      await lastValueFrom(this.subscriptionHttpService.regenerateTermOfUse(companyId));
      await this.refreshSubscriptionSignedTerms(companyId);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshPlansPricing(companyId: number, upsellCampaign?: string): Promise<void> {
    try {
      this.setState({
        plansPricing: await lastValueFrom(this.subscriptionHttpService.getPlansPricing(companyId, upsellCampaign)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateCard(companyId: number, clientSecret: string): Promise<boolean> {
    try {
      await lastValueFrom(this.subscriptionHttpService.updateCard(companyId, clientSecret));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async deleteCard(companyId: number, clientSecret: string): Promise<boolean> {
    try {
      await lastValueFrom(this.subscriptionHttpService.deleteCard(companyId, clientSecret));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async deleteCustomerOnStripe(companyId: number, clientSecret: string): Promise<boolean> {
    try {
      await lastValueFrom(this.subscriptionHttpService.deleteCustomerOnStripe(companyId, clientSecret));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async getCanActivateCode(companyId: number): Promise<MessageCode | null> {
    try {
      return await lastValueFrom(this.subscriptionHttpService.getCanActivateCode(companyId));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async activate(selectedPlan: SubscriptionPlan, company: Company, token?: string | null): Promise<boolean> {
    try {
      await lastValueFrom(this.subscriptionHttpService.activate(selectedPlan, company, token));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async changeSubscriptionPlan(selectedPlan: SubscriptionPlan, company: Company): Promise<void> {
    try {
      await lastValueFrom(this.subscriptionHttpService.changePlan(selectedPlan, company));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshBalance(companyId: number): Promise<void> {
    try {
      this.setState({
        balance: await lastValueFrom(this.subscriptionHttpService.getBalance(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshDoubtfulBalance(companyId: number): Promise<void> {
    try {
      this.setState({
        doubtfulBalance: await lastValueFrom(this.subscriptionHttpService.getDoubtfulBalance(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getSubscriptionDetails(company: Company): Promise<SubscriptionDetail[] | null> {
    try {
      return await lastValueFrom(this.subscriptionHttpService.getDetails(company.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getSubscriptionTerms(company: Company): Promise<PlanTerms | null> {
    if (!company.subscription?.plan) {
      return null;
    }
    try {
      return await lastValueFrom(this.subscriptionHttpService.getTerms(company.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async triggerActivationAfterDougsLegalCreation(companyId: number): Promise<boolean> {
    try {
      await lastValueFrom(this.subscriptionHttpService.triggerActivationAfterDougsLegalCreation(companyId));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async checkDetailsUpcommingInvoice(
    companyId: number,
    plan: SubscriptionPlan | null,
  ): Promise<SubscriptionPlan | null> {
    try {
      return lastValueFrom(this.subscriptionHttpService.checkDetailsUpcommingInvoice(companyId, plan));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateTrialPeriodDays(companyId: number, trialPeriodDays: number): Promise<void> {
    try {
      await lastValueFrom(this.subscriptionHttpService.updateTrialPeriodDays(companyId, trialPeriodDays));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updatePeriodStartsAt(companyId: number, periodStartsAt: Date | string): Promise<void> {
    try {
      await lastValueFrom(this.subscriptionHttpService.updatePeriodStartsAt(companyId, periodStartsAt));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateSubscriptionCustomAmount(company: Company, customAmount: number | null): Promise<boolean> {
    try {
      await lastValueFrom(this.subscriptionHttpService.updateSubscriptionCustomAmount(company, customAmount));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }
}
