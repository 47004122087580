import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import {
  Balance,
  MessageCode,
  PlanTerms,
  Subscription,
  SubscriptionDetail,
  SubscriptionPlan,
} from '@dougs/subscription/dto';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionHttpService {
  constructor(private readonly http: HttpClient) {}

  updatePeriodsEndDate(company: Company, periodEndsAt: Date | string): Observable<Subscription> {
    return this.http.post<Subscription>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/subscription/actions/change-period-ends-at`,
      {
        periodEndsAt,
      },
    );
  }

  getSignedTerms(companyId: number): Observable<Attachment[]> {
    return this.http.get<Attachment[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/signed-terms`,
    );
  }

  regenerateTermOfUse(companyId: number): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/actions/regenerate-terms`,
      {},
    );
  }

  getPlans(companyId: number): Observable<SubscriptionPlan[]> {
    return this.http.get<SubscriptionPlan[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/plans`);
  }

  getPlansPricing(companyId: number, upsellCampaign?: string): Observable<SubscriptionPlan[]> {
    if (upsellCampaign) {
      return this.http.get<SubscriptionPlan[]>(
        `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/plans-pricing?upsell-campaign=${upsellCampaign}`,
      );
    }
    return this.http.get<SubscriptionPlan[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/plans-pricing`,
    );
  }

  updateCard(companyId: number, clientSecret: string): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/actions/change-card`,
      { clientSecret },
    );
  }

  deleteCard(companyId: number, clientSecret: string): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/actions/delete-card`,
      { clientSecret },
    );
  }

  deleteCustomerOnStripe(companyId: number, clientSecret: string): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/actions/delete-customer-on-stripe`,
      { clientSecret },
    );
  }

  getCanActivateCode(companyId: number): Observable<MessageCode> {
    return this.http.get<MessageCode>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/can-activate`,
    );
  }

  activate(selectedPlan: SubscriptionPlan, company: Company, token?: string | null): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/subscription/actions/activate`,
      { plan: selectedPlan.id, token },
    );
  }

  changePlan(selectedPlan: SubscriptionPlan, company: Company): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/subscription/actions/change-plan`,
      { plan: selectedPlan.id },
    );
  }

  getBalance(companyId: number): Observable<Balance> {
    return this.http.get<Balance>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/balance`,
    );
  }

  getDoubtfulBalance(companyId: number): Observable<Balance> {
    return this.http.get<Balance>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/doubtfulBalance`,
    );
  }

  getDetails(companyId: number): Observable<SubscriptionDetail[]> {
    return this.http.get<SubscriptionDetail[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/details`,
    );
  }

  getTerms(companyId: number): Observable<PlanTerms> {
    return this.http.get<PlanTerms>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/terms`,
    );
  }

  triggerActivationAfterDougsLegalCreation(companyId: number): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/actions/triggerActivationAfterDougsLegalCreation`,
      {},
    );
  }

  checkDetailsUpcommingInvoice(companyId: number, plan: SubscriptionPlan | null): Observable<SubscriptionPlan | null> {
    return this.http.post<SubscriptionPlan | null>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/details-upcoming-invoice`,
      {
        plan,
      },
    );
  }

  updateTrialPeriodDays(companyId: number, trialPeriodDays: number): Observable<number> {
    return this.http.post<number>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/actions/update-trial-period-days`,
      { trialPeriodDays },
    );
  }

  updatePeriodStartsAt(companyId: number, periodStartsAt: Date | string): Observable<Date> {
    return this.http.post<Date>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/actions/update-period-starts-at`,
      { periodStartsAt },
    );
  }

  updateSubscriptionCustomAmount(company: Company, customAmount: number | null): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/subscription/actions/update-custom-amount`,
      { customAmount },
    );
  }
}
