import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { ReferralStats, ReferrerDashboard } from '@dougs/subscription/dto';
import { ReferrerHttpService } from '../http/referrer.http';

interface ReferrerState {
  referralStats: ReferralStats;
}

@Injectable({
  providedIn: 'root',
})
export class ReferrerStateService extends StateService<ReferrerState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly referrerHttpService: ReferrerHttpService,
  ) {
    super();
  }

  readonly referralStats$: Observable<ReferralStats> = this.select((state) => state.referralStats);

  async refreshReferralStats(userId: number): Promise<void> {
    try {
      this.setState({
        referralStats: await lastValueFrom(this.referrerHttpService.getReferralStats(userId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getReferrerDashboard(userUuid: string): Promise<ReferrerDashboard | null> {
    try {
      return toPromise(this.referrerHttpService.getReferrerDashboard(userUuid));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }
}
