import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { ReferralStats, ReferrerDashboard } from '@dougs/subscription/dto';

@Injectable({
  providedIn: 'root',
})
export class ReferrerHttpService {
  constructor(private readonly http: HttpClient) {}

  getReferralStats(userId: number): Observable<ReferralStats> {
    return this.http.get<ReferralStats>(`${AppConfig.settings.legacyApiServerUrl}/users/${userId}/referral/stats`);
  }

  getReferrerDashboard(userUuid: string): Observable<ReferrerDashboard> {
    return this.http.get<ReferrerDashboard>(
      `${AppConfig.settings.legacyApiServerUrl}/referrer-dashboard?uuid=${userUuid}`,
    );
  }
}
