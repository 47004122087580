import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { BillingInvoice, BillingInvoiceItem } from '@dougs/subscription/dto';

@Injectable({
  providedIn: 'root',
})
export class BillingInvoiceHttpService {
  constructor(private readonly http: HttpClient) {}

  getBillingInvoices(companyId: number): Observable<BillingInvoice[]> {
    return this.http.get<BillingInvoice[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/billing-invoices`,
    );
  }

  getPendingBillingInvoice(companyId: number): Observable<BillingInvoice> {
    return this.http.get<BillingInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/billing-invoices/pending`,
    );
  }

  getBillingInvoice(companyId: number, invoiceId: number): Observable<BillingInvoice> {
    return this.http.get<BillingInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/billing-invoices/${invoiceId}`,
    );
  }

  updateBillingInvoice(billingInvoice: BillingInvoice): Observable<BillingInvoice> {
    return this.http.post<BillingInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${billingInvoice.billedCompanyId}/billing-invoices/${billingInvoice.id}`,
      billingInvoice,
    );
  }

  createBillingInvoice(companyId: number, items: BillingInvoiceItem[]): Observable<BillingInvoice> {
    return this.http.post<BillingInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/billing-invoices`,
      {
        paidAt: null,
        items,
      },
    );
  }

  addBillingInvoiceItem(
    companyId: number,
    subscriptionId: number,
    items: BillingInvoiceItem[],
  ): Observable<BillingInvoice> {
    return this.http.post<BillingInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/billing-invoices?skipFinalize=true`,
      {
        paidAt: null,
        items,
        subscriptionId,
      },
    );
  }

  removeBillingInvoiceItem(billingInvoice: BillingInvoice, billingInvoiceItem: BillingInvoiceItem): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${billingInvoice.billedCompanyId}/billing-invoices/${billingInvoice.id}/billing-invoice-items/${billingInvoiceItem.id}`,
    );
  }

  chargeBillingInvoice(billingInvoice: BillingInvoice): Observable<BillingInvoice> {
    return this.http.post<BillingInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${billingInvoice.billedCompanyId}/billing-invoices/${billingInvoice.id}/actions/charge`,
      {},
    );
  }
}
