import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { mergeObjects } from '@dougs/core/utils';
import { BillingInvoice, BillingInvoiceItem } from '@dougs/subscription/dto';
import { BillingInvoiceHttpService } from '../http/billing-invoice.http';

interface BillingInvoiceState {
  billingInvoices: BillingInvoice[];
  pendingBillingInvoice: BillingInvoice;
}

@Injectable({
  providedIn: 'root',
})
export class BillingInvoiceStateService extends StateService<BillingInvoiceState> {
  readonly billingInvoices$: Observable<BillingInvoice[]> = this.select((state) => state.billingInvoices);
  readonly pendingBillingInvoice$: Observable<BillingInvoice> = this.select((state) => state.pendingBillingInvoice);
  readonly unpaidBillingInvoices$: Observable<BillingInvoice[]> = this.select((state) =>
    state.billingInvoices.filter((billingInvoice) => !billingInvoice.isPaid && !billingInvoice.isPaidOnPartnerSide),
  );

  constructor(
    private readonly logger: LoggerService,
    private readonly billingInvoiceHttpService: BillingInvoiceHttpService,
  ) {
    super();
  }

  async refreshBillingInvoices(companyId: number): Promise<void> {
    try {
      this.setState({
        billingInvoices: await lastValueFrom(this.billingInvoiceHttpService.getBillingInvoices(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshPendingBillingInvoice(companyId: number): Promise<void> {
    try {
      this.setState({
        pendingBillingInvoice: await lastValueFrom(this.billingInvoiceHttpService.getPendingBillingInvoice(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getBillingInvoice(companyId: number, invoiceId: number): Promise<BillingInvoice | null> {
    try {
      return await lastValueFrom(this.billingInvoiceHttpService.getBillingInvoice(companyId, invoiceId));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateBillingInvoice(billingInvoice: BillingInvoice): Promise<void> {
    try {
      const billingInvoiceUpdated: BillingInvoice = await lastValueFrom(
        this.billingInvoiceHttpService.updateBillingInvoice(billingInvoice),
      );

      this.setState({
        billingInvoices: this.state.billingInvoices.map((billingInvoiceIterated) =>
          billingInvoiceIterated.id === billingInvoiceUpdated.id
            ? mergeObjects(billingInvoice, billingInvoiceUpdated)
            : billingInvoiceIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addServiceInBillingInvoice(
    company: Company,
    billingInvoice: BillingInvoice,
    billingInvoiceItems: BillingInvoiceItem[],
  ): Promise<boolean> {
    try {
      const billingInvoiceCreated = await lastValueFrom(
        this.billingInvoiceHttpService.addBillingInvoiceItem(
          company.id,
          billingInvoice?.subscriptionId,
          billingInvoiceItems,
        ),
      );

      this.setState({
        billingInvoices: [
          ...this.state.billingInvoices,
          await lastValueFrom(
            this.billingInvoiceHttpService.getBillingInvoice(
              billingInvoiceCreated.billedCompanyId,
              billingInvoiceCreated.id,
            ),
          ),
        ],
      });

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async removeServiceInBillingInvoice(
    billingInvoice: BillingInvoice,
    billingInvoiceItem: BillingInvoiceItem,
  ): Promise<boolean> {
    try {
      await lastValueFrom(this.billingInvoiceHttpService.removeBillingInvoiceItem(billingInvoice, billingInvoiceItem));
      const updatedBillingInvoiceItems: BillingInvoiceItem[] = billingInvoice.items.filter(
        (item) => item.id !== billingInvoiceItem.id,
      );
      const updatedBillingInvoice: BillingInvoice = { ...billingInvoice, items: updatedBillingInvoiceItems };
      this.setState({
        billingInvoices: [
          ...this.state.billingInvoices.map((invoice) =>
            invoice.id === billingInvoice.id ? updatedBillingInvoice : invoice,
          ),
        ],
      });

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async createBillingInvoice(company: Company, billingInvoiceItems: BillingInvoiceItem[]): Promise<boolean> {
    try {
      const billingInvoiceCreated = await lastValueFrom(
        this.billingInvoiceHttpService.createBillingInvoice(company.id, billingInvoiceItems),
      );

      this.setState({
        billingInvoices: [
          ...this.state.billingInvoices,
          await lastValueFrom(
            this.billingInvoiceHttpService.getBillingInvoice(
              billingInvoiceCreated.billedCompanyId,
              billingInvoiceCreated.id,
            ),
          ),
        ],
      });

      return true;
    } catch (e) {
      this.logger.error(e);

      return false;
    }
  }

  async chargeBillingInvoice(billingInvoice: BillingInvoice): Promise<void> {
    try {
      const billingInvoiceUpdated: BillingInvoice = await lastValueFrom(
        this.billingInvoiceHttpService.chargeBillingInvoice(billingInvoice),
      );

      this.setState({
        billingInvoices: this.state.billingInvoices.map((billingInvoiceIterated) =>
          billingInvoiceUpdated.id === billingInvoiceIterated.id ? billingInvoiceUpdated : billingInvoiceIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
